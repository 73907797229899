import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './assets/cruxologie.css';
import Accueil, { accueilLoader } from './pages/Accueil';

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Accueil />
          </Suspense>
        ),
        loader: accueilLoader,
      },
      {
        path: "ressource",
        children: [
          {
            index: true,
            lazy: () => import('./pages/Ressource').then(module => ({
              Component: module.default,
              loader: module.ressourceLoader,
            })),
          },
          {
            path: "exercice",
            children: [
              {
                index: true,
                lazy: () => import('./pages/ExerciceListe').then(module => ({
                  Component: module.default,
                  loader: module.exerciceListeLoader,
                })),
              },
              {
                path: ":nom",
                lazy: () => import('./pages/Exercice').then(module => ({
                  Component: module.default,
                  loader: module.exerciceLoader,
                })),
              }
            ]
          },
          {
            path: "programme",
            lazy: () => import('./pages/Programme').then(module => ({
              Component: module.default,
              loader: module.programmeLoader,
            })),
          },
          {
            path: "autre",
            children: [
              {
                index: true,
                lazy: () => import('./pages/Autre').then(module => ({
                  Component: module.default,
                  loader: module.autreLoader,
                })),
              },
              {
                path: ":id",
                lazy: () => import('./pages/DescriptionAutreRessource').then(module => ({
                  Component: module.default,
                  loader: module.descriptionAutreRessourceLoader,
                })),
              }
            ]
          }
        ]
      },
      {
        path: "article",
        children: [
          {
            index: true,
            lazy: () => import('./pages/ArticleListe').then(module => ({
              Component: module.default,
              loader: module.articleListeLoader,
            })),
          },
          {
            path: ":nom",
            lazy: () => import('./pages/Article').then(module => ({
              Component: module.default,
              loader: module.articleLoader,
            })),
          }
        ]
      },
      {
        path: "coaching",
        lazy: () => import('./pages/Coaching').then(module => ({
          Component: module.default,
          loader: module.coachingLoader,
        })),
      },
      {
        path: "contact",
        lazy: () => import('./pages/Contact').then(module => ({
          Component: module.default,
          loader: module.contactLoader,
        })),
      },
      {
        path: "qui-sommes-nous",
        lazy: () => import('./pages/QuiSommesNous').then(module => ({
          Component: module.default,
          loader: module.quiSommesNousLoader,
        })),
      },
      {
        path: "profil",
        lazy: () => import('./pages/Profil').then(module => ({
          Component: module.default,
          loader: module.profilLoader,
        })),
      },
      {
        path: "shop/:produit",
        lazy: () => import('./pages/Shop').then(module => ({
          Component: module.default,
          loader: module.shopLoader,
        })),
      },
      {
        path: "admin/*",
        lazy: () => import('./pages/Admin').then(module => ({
          Component: module.default,
        })),
      },
      {
        path: "connexion",
        lazy: () => import('./pages/Connexion').then(module => ({
          Component: module.default,
          loader: module.connexionLoader,
        })),
      },
      {
        path: "cgv",
        lazy: () => import('./pages/InfoLegal').then(module => ({
          Component: module.default,
          loader: module.infoLegalLoader,
        })),
      },
      {
        path: "image/:id",
        lazy: () => import('./pages/Image').then(module => ({
          Component: module.default,
        })),
      },
      {
        path: "video/:id",
        lazy: () => import('./pages/Video').then(module => ({
          Component: module.default,
        })),
      },
      {
        path: "description/:produitInPath",
        lazy: () => import('./pages/DescriptionProduit').then(module => ({
          Component: module.default,
        })),
      },
      {
        path: "Erreur",
        lazy: () => import('./pages/Erreur').then(module => ({
          Component: module.default,
        })),
      }
    ]
  }
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);