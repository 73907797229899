import React, { useRef, useState, useEffect, lazy } from 'react';
import { ScrollRestoration } from "react-router-dom";
import AppNavbar from '../components/navbar.component';
import { Link } from 'react-router-dom';
import '../assets/cruxologie.css';
import '../assets/accueil.css';
import'../assets/bootstrap.css';
import logoAccueil from '../assets/images/logo-accueil.webp';
import im1 from '../assets/images/im1.webp';
import im2 from '../assets/images/im2.webp';
import im3 from '../assets/images/im3.webp';
import iconeArticle from '../assets/images/icone-bouton/article-blanc.png';
import iconePuzzle from '../assets/images/icone-bouton/puzzle-blanc.png';
import iconeCoaching from '../assets/images/icone-bouton/coaching-blanc.png';
import goTop from '../assets/images/go-top.png';

const Footer = lazy(() => import('../components/footer.component'));

export default function Accueil() {

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    
  const scrollToSection1 = () => {
    section1Ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };
  const scrollToSection2 = () => {
    section2Ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };
  const scrollToSection3 = () => {
    section3Ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };


    const scrollFunction = () => {
        if (document.body.scrollTop > 2 || document.documentElement.scrollTop > 2) {
           setShowScrollToTop(true);
        } else {
          setShowScrollToTop(false);
        }
      };


    useEffect(() => {
        window.addEventListener('scroll', scrollFunction);
        
        return () => {
          window.removeEventListener('scroll', scrollFunction);
        };
      }, []);

    return  <div id="root2">
                <ScrollRestoration />
                <AppNavbar />
                <div className='container-fluid'>
                    <div className="container-accueil-1">
                        
                        <div className="container-image-accueil">
                            <img src={logoAccueil} alt="Accueil" className="image-accueil" width={934} height={333}/>
                        </div>

                        <div className="container-text-accueil">
                            <span className="phraseAccueil">Coaching et ressources en escalade : S'entrainer, progresser, enseigner.</span>
                        </div>
                        <div className="container container-bouton-accueil">
                            <div className="button-container container2-bouton-accueil">
                                <div className='fcc fmc fc'><button className="scroll-button" id="scroll-button-article" onClick={scrollToSection1}><img src={iconeArticle} width={70} height={70} alt="icone Article"/></button><span>Articles</span></div>
                                <div className='fcc fmc fc'><button className="scroll-button" id="scroll-button-exercice" onClick={scrollToSection2}><img src={iconePuzzle} width={70} height={70} alt="icone Puzzle"/></button><span>Ressources</span></div>
                                <div className='fcc fmc fc'><button className="scroll-button" id="scroll-button-coaching" onClick={scrollToSection3}><img src={iconeCoaching} width={70} height={70} alt="icone Coaching"/></button><span>Coaching</span></div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="container-section-accueil">
                        <section ref={section1Ref} id="section1-accueil">
                            <div className="container-text-acc-gauche">
                                <span className="text-acc">Retrouvez tous nos articles sur l’entrainement
                                    <ul className="text-acc-1">
                                        <li>Revue d’articles scientifiques</li>
                                        <li>Astuces et informations</li>
                                        <li>discussions</li>
                                    </ul>
                                </span>
                                <Link to="/article"><button className="clic-button primary-button accueil-button-nav">Commencer la lecture</button></Link>
                            </div>
                            <Link to="/article" className="container-image-acc-droite"><img src={im1} alt="Femme qui étudie" className="image-acc" height={465} width={800}/></Link>
                        </section>
                        <section ref={section2Ref} id="section2-accueil" >
                            <Link to="/ressource" className="container-image-acc-gauche"><img src={im2} alt="Femme qui grimpe sur un bloc" className="image-acc" height={700} width={1000}/></Link>
                            <div className="container-text-acc-droite">
                                <span className="text-acc">Piochez parmi un large choix  d’exercices disponible
                                    <ul className="text-acc-1">
                                        <li>Renforcement musculaire</li>
                                        <li>Exercices de préparation mentale</li>
                                        <li>Plan d’entrainement physique</li>
                                    </ul>
                                </span>
                                <Link to="/ressource"><button className="clic-button primary-button accueil-button-nav">Accéder aux ressources</button></Link>
                            </div>
                        </section>
                        <section ref={section3Ref} id="section3-accueil" >
                            <div className="container-text-acc-gauche">
                                <span className="text-acc">Bénéficiez d’un coaching personnalisé
                                    <ul className="text-acc-1">
                                        <li>Diagnostique et plannification</li>
                                        <li>Suivi régulier</li>
                                        <li>Accès à toutes les ressources</li>
                                    </ul>
                                </span>
                                <Link to="/coaching"><button className="clic-button primary-button accueil-button-nav">commencez votre entraînement</button></Link>
                            </div>
                            <Link to="/coaching" className="container-image-acc-droite"><img src={im3} alt="Deux personnes qui réfléchissent devant un tableau" className="image-acc" width={857} height={486}/></Link>
                        </section>
                    </div>
                    <button onClick={() => window.scrollTo({top: 0,left: 0,behavior: 'smooth'})} id="scrollBtn" className={showScrollToTop ? 'visible' : ''} title="Retourner en haut de la page"><img src={goTop} alt='retour haut'loading="lazy"/></button> 

                </div>
                <Footer />
            </div>
}
export const accueilLoader = async () => {
    return ""
}   