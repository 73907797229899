import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL + "/auth/";

const AuthService = {
    
    login: async (userLogInfo) => {
        const response = await axios
            .post(API_URL + "signin", userLogInfo);
        if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
            return Promise.resolve(response.data);
        } else{
            return Promise.reject(response.data);
        }
        //return response.data;
    },

    logout: () => {
        localStorage.removeItem("user");
        return Promise.resolve();
    },

    register: (username, email, password) => {
        return axios.post(API_URL + "signup", {
            username,
            email,
            password
        });
    },

    getCurrentUser: () => {
        if (localStorage.getItem('user')){
            return Promise.resolve(JSON.parse(localStorage.getItem('user')));
        }
        return Promise.reject("No user");
    },

    checkAuth: () => {
        return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).roles.includes("ROLE_ADMIN") ? Promise.resolve() : Promise.reject() : Promise.reject();
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('user');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },

    getIdentity: () => {
        return localStorage.getItem('user') ? Promise.resolve(JSON.parse(localStorage.getItem('user'))) : Promise.reject();
    },
    
    getPermissions: () => {
        return localStorage.getItem('user') ? Promise.resolve(JSON.parse(localStorage.getItem('user')).roles) : Promise.reject();
    }

};

export default AuthService;